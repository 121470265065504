const todos = [
	{
		id: 'wggrhtjw',
		description: 'Physics',
		deadline: '',
		dateAdded: '2021-08-22T06:38:59.531Z',
		groupId: 'ksafla',
		complete: false,
	},
	{
		id: 'wggtehw',
		description: 'Chemistry',
		deadline: '',
		dateAdded: '2021-08-22T06:38:59.531Z',
		groupId: 'ksafla',
		complete: false,
	},
	{
		id: 'wgcddscgw',
		description: 'Mathematics',
		deadline: '',
		dateAdded: '2021-08-22T06:38:59.531Z',
		groupId: 'ksafla',
		complete: false,
	},
	{
		id: 'wghrehgw',
		description: 'Computer Science',
		deadline: '',
		dateAdded: '2021-08-22T06:38:59.531Z',
		groupId: 'ksafla',
		complete: false,
	},
	{
		id: 'wggw',
		description: 'Clean room',
		deadline: '',
		dateAdded: '2021-08-22T06:38:59.531Z',
		groupId: 'asdfsf',
		complete: false,
	},
	{
		id: 'asdrjrjhfsf',
		description: 'Clean car',
		deadline: '',
		dateAdded: '2021-08-22T06:38:59.531Z',
		groupId: 'asdfsf',
		complete: false,
	},
	{
		id: 'regassgdfhh',
		description: 'Program Design',
		deadline: '',
		dateAdded: '2021-08-22T06:38:59.531Z',
		groupId: 'asdfa',
		complete: false,
	},

	{
		id: 'regaqgesdfhh',
		description: 'Logic Design',
		deadline: '',
		dateAdded: '2021-08-22T06:38:59.531Z',
		groupId: 'asdfa',
		complete: false,
	},
	{
		id: 'regarhrsdfhh',
		description: 'Discrete Structures',
		deadline: '',
		dateAdded: '2021-08-22T06:38:59.531Z',
		groupId: 'asdfa',
		complete: false,
	},
	{
		id: 'regasdfhh',
		description: 'Harry Potter and the Order of Phoenix',
		deadline: '',
		dateAdded: '2021-08-22T06:38:59.531Z',
		groupId: 'asdfgewer',
		complete: false,
	},
	{
		id: 'regerghh',
		description: 'The DaVinci Code',
		deadline: '',
		dateAdded: '2021-08-22T06:38:59.531Z',
		groupId: 'asdfgewer',
		complete: false,
	},
	{
		id: 'revadwvghh',
		description: '1984',
		deadline: '',
		dateAdded: '2021-08-22T06:38:59.531Z',
		groupId: 'asdfgewer',
		complete: false,
	},
	{
		id: 'wgwwhw',
		description: 'Angels & Demons',
		deadline: '',
		dateAdded: '2021-08-22T06:38:59.531Z',
		groupId: 'asdfgewer',
		complete: false,
	},
	{
		id: 'asdfaf',
		description: 'Pencils',
		deadline: '',
		dateAdded: '2021-08-22T06:38:59.531Z',
		groupId: 'sgdsd',
		complete: false,
	},
	{
		id: 'erbeg',
		description: 'A4 Paper',
		deadline: '',
		dateAdded: '2021-08-22T06:38:59.531Z',
		groupId: 'sgdsd',
		complete: false,
	},
	{
		id: 'sdfwe',
		description: 'Sketches',
		deadline: '',
		dateAdded: '2021-08-22T06:38:59.531Z',
		groupId: 'sgdsd',
		complete: false,
	},
	{
		id: 'reghh',
		description: 'Ruler',
		deadline: '',
		dateAdded: '2021-08-22T06:38:59.531Z',
		groupId: 'sgdsd',
		complete: false,
	},
	{
		id: 'reggrehh',
		description: 'Make UI responsive',
		deadline: '',
		dateAdded: '2021-08-22T06:38:59.531Z',
		groupId: 'wgwg',
		complete: false,
	},
	{
		id: 'regwgwefhh',
		description: 'Add export and import feature',
		deadline: '',
		dateAdded: '2021-08-22T06:38:59.531Z',
		groupId: 'wgwg',
		complete: false,
	},
	{
		id: 'reqergghh',
		description: 'Add animations',
		deadline: '',
		dateAdded: '2021-08-22T06:38:59.531Z',
		groupId: 'wgwg',
		complete: false,
	},
	{
		id: 'reqesgrgghh',
		description: 'Bug fixes and optimization',
		deadline: '',
		dateAdded: '2021-08-22T06:38:59.531Z',
		groupId: 'wgwg',
		complete: false,
	},
]

const groups = {
	all: { color: 'yellow', name: 'All todos' },
	ksafla: { color: 'yellow', name: 'Assignments' },
	asdfsf: { color: 'red', name: 'Cleaning' },
	asdfgewer: { color: 'violet', name: 'Reading' },
	wgwg: { color: 'green', name: 'Updates' },
	asdfa: { color: 'pink', name: 'Exams' },
	sgdsd: { color: 'blue', name: 'Shopping List' },
}

const sample = { groups, todos }

export default sample
